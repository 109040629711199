import { appStorage } from "./localStorage";
import { User } from "./types";

export function prepareHeaders(headers: Headers) {
  const user = appStorage.getItem<User>("user");
  if (!user) {
    return;
  }
  headers.set("X-Customer-Id", String(user.id));
  headers.set("X-Client-Id", String(user.clientId));

  return headers;
}
